@charset "utf-8";
//@include sp {}
//@include tb {}
//@include pc {}
//@include onlyTB{}
//@include onlyPC{}

/* */
.c-share {
  padding-left: 4vw;
  bottom: 36px;
  position: relative;
  top: -20px;
  @include pc {
    position: fixed;
    bottom: 24px;
    left: 3.1vw;
    padding: 0;
    top: auto;
  }
  &__title {
    display: inline-block;
    white-space: nowrap;
    color: #f9dade;
    font-size: 11px;
    margin-right: 12px;
    letter-spacing: 0.1em;
    @include pc {
      display: block;
      position: absolute;
      top: -46px;
      margin-right: 3px;
      margin-bottom: 20px;
      font-feature-settings: "vpal","vert","vkrn";
      writing-mode: vertical-rl;
      text-orientation: upright;
    }
  }
  &__list {
    display: inline-block;
    white-space: nowrap;
    @include pc {
      display: block;
    }
  }
  &__item {
    margin-right: 17px;
    display: inline-block;
    &:last-of-type {
      margin-right: 0;
    }
    @include pc {
      display: block;
      margin-right: 0;
      margin-bottom: 16px;
    }
    a {
      display: block;
      img {
        vertical-align: middle;
      }
    }
    &.-twitter {
      img {
        width: 20px;
        height: 16px;
        @include pc {
          width: 18px;
          height: 15px;
        }
      }
    }
    &.-facebook {
        width: 20px;
        height: 20px;
      img {
        @include pc {
          width: 18px;
          height: 18px;
        }
      }
    }
    &.-line {
      img {
        width: 20px;
        height: 20px;
        @include pc {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}
