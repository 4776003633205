@charset "utf-8";
//@include sp {}
//@include tb {}
//@include pc {}
//@include onlyTB{}
//@include onlyPC{}

/* */
.text__baka {
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(.55, .09, .68, .53);
  @include pc {
    .l-header & {
      opacity: 0;
      z-index: 999;
    }
    .l-header.is-fixed & {
      opacity: 1;
    }
  }
  .l-toggle & {
    z-index: 999999;
    opacity: 1;
  }
  position: fixed;
  font-size: 10px;
  color: #f9dade;
  text-align: center;
  top: 83px;
  right: 35px;
  transform: scale(0.9);
  @include tb {
    top: 100px;
    right: 50px;
    font-size: 11px;
    transform: scale(1);
  }
  i {
    display: block;
    position: relative;
    &::before {
      content: "";
      width: 1px;
      height: 40px;
      display: block;
      background: #f9dade;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin: 2px 0;
      @include tb {
        width: 1px;
        height: 80px;
      }
    }
  }
}
.scroll__baka {
  transition: opacity 0.2s cubic-bezier(.55, .09, .68, .53);
  letter-spacing: .1em;
  .l-header & {
    display: none;
    @include tb {
      display: block;
    }
  }
  @include tb {
    font-feature-settings: "vpal","vert","vkrn";
    writing-mode: vertical-rl;
    text-orientation: upright;
    .l-main & {
      display: none;
    }
  }
  .l-header & {
    opacity: 1;
  }
  .l-header.is-fixed & {
    opacity: 0;
  }
  position: relative;
  font-size: 10px;
  color: #1c1c1c;
  text-align: right;
  top: -25px;
  right: -5%;
  transform: scale(0.9);
  @include tb {
    position: fixed;
    top: 544px;
    bottom: 30px;
    right: 46px;
    font-size: 11px;
    transform: scale(1);
    text-align: center;
  }
  i {
    display: inline-block;
    position: relative;
    &::before {
      content: "";
      width: 40px;
      height: 1px;
      display: inline-block;
      background: #1c1c1c;
      position: relative;
      margin: 0 5px;
      vertical-align: middle;
      @include tb {
        width: 1px;
        height: 80px;
        margin: 5px 0;
        vertical-align: inherit;
      }
    }
  }
}