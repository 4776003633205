@charset "utf-8";
//@include sp {}
//@include tb {}
//@include pc {}
//@include onlyTB{}
//@include onlyPC{}

/* */
.l-gnav {
  .l-header & {
    display: none;
    @include pc {
      font-size: 13px;
      display: block;
      position: absolute;
      right: 5px;
      bottom: 0;
      padding-top: 48px;
      padding-bottom: 27px;
      &__item {
        display: inline-block;
        margin: 0 10px;
        vertical-align: middle;
        &:last-of-type {
          display: none;
        }
        a {
          position: relative;
          display: block;
          line-height: 20px;
          color: #fff;
          letter-spacing: 0.1em;
          transform: perspective(1px) translateZ(0);
          -webkit-tap-highlight-color: rgba(0,0,0,0);
          &:first-of-type {
            margin-left: 0;
          }
          &:nth-last-of-type(1) {
            margin-right: 0;
          }
          &::before {
            position: absolute;
            right: 100%;
            bottom: -5px;
            left: 0;
            z-index: -1;
            height: 2px;
            content: "";
            background: $c-primary;
            transition-timing-function: ease-out;
            transition-duration: 0.2s;
            transition-property: right;
          }
          &:hover {
            color: #fff;
            &::before {
              right: 0;
            }
          }
        }
      }
    }
  }
  .l-toggle & {
    margin-top: 80px;
    &__list {
      padding: 0 10vw;
    }
    &__item {
      margin-bottom: 43px;
      a {
        display: block;
        padding: 10px 0;
        line-height: 1;
        color: #fff;
        font-size: 20px;
        letter-spacing: 0.1em;
      }
    }
    @include pc {
      margin-top: 95px;
      &__list {
        padding: 0 60px;
      }
      &__item {
        vertical-align: middle;
        margin-bottom: 45px;
        a {
          transform: perspective(1px) translateZ(0);
          -webkit-tap-highlight-color: rgba(0,0,0,0);
          &:first-of-type {
            margin-left: 0;
          }
          &:nth-last-of-type(1) {
            margin-right: 0;
          }
          &:hover {
            color: #f9dade;
          }
        }
      }
    }
  }
}