@charset "utf-8";
//@include sp {}
//@include tb {}
//@include pc {}
//@include onlyTB{}
//@include onlyPC{}

/* */
.l-footer {
  position: relative;
  background: $c-primary;
  &__inner {
    padding: 40px 0 calc(668vw / 7.68);
    @include pc {
      max-width: $point-wide;
      margin: 0 auto;
      @include clearfix;
      padding: 60px 0 54px;
    }
  }
  &-bnr {
    &__list {
      margin-bottom: 25px;
      text-align: center;
      @include pc {
        float: right;
        width: 700px;
        margin-bottom: 40px;
      }
    }
    &__item {
      display: inline-block;
      width: 265px;
      a {
        display: block;
      }
      img {
        width: 100%;
        height: auto;
      }
      @include pc {
        width: 242px;
      }
    }
  }
  &__copy {
    display: block;
    line-height: 1;
    text-align: center;
    font-size: 10px;
    letter-spacing: .1em;
    @include pc {
      float: right;
      width: 700px;
      font-size: 12px;
    }
  }
  .pagetop {
    position: absolute;
    right: 6vw;
    top: -105px;
    width: 29%;
    max-width: 110px;
    a{
      display: block;
    }
    img {
      width: 100%;
      height: auto;
    }
    @include pc {
      top: -107px;
      right: 7.6vw;
      width: auto;
    }
  }
}