@charset "utf-8";
//@include sp {}
//@include tb {}
//@include pc {}
//@include onlyTB{}
//@include onlyPC{}

/* */
.c-sns {
  @include pc {
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
  }
  &__item {
    //flex-basis: 290px;
  }
  &__title {
    font-size: 10px;
    line-height: 1;
    color : #878787;
    margin-bottom: 14px;
    @include pc {
      font-size: 12px;
      margin-bottom: 20px;
    }
  }
  &__twitter {
    width: 100%;
    border: 1px solid #e9e9e9;
    //margin-bottom: 35px;
    @include pc {
      //width: 290px;
      margin-bottom: 0;
    }
    #twitter-widget-0 {
      width: 100%!important;
      @include pc {
        width: 290px;
      }

    }
  }
  
  #instafeed {
    width: 100%;
    border: 1px solid #e9e9e9;
    @include pc {
      width: 260px;
    }
    .item {
      width: 100%;
    }
    a {
      display: block;
      height: 100%;
      background: center / cover no-repeat;
      transition: opacity .2s;
      &:hover {
        opacity: .8;
      }
    }
    img {
      width: 100%;
    }
  }
}