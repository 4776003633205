@charset "utf-8";
//@include sp {}
//@include tb {}
//@include pc {}
//@include onlyTB{}
//@include onlyPC{}

/* */
.modal .c-modal {
  &__img {
    margin-bottom: 30px;
    @include pc {
      width: 330px;
      margin-bottom: 40px;
    }
  }
  &__text {
    font-size: 15px;
    line-height: (60/30);
    @include pc {
      font-size: 18px;
      line-height: (40/18);
    }
    span {
      color: #cb4557;
    }
  }
}