@charset "UTF-8";
/* ==========================================================================
   Foundation
   ========================================================================== */
/* */
/* */
/* */
/* */
body {
  background: #dc8590;
  color: #1c1c1c;
  font-size: 13px;
  line-height: 1.6;
  font-family: 'Kosugi Maru', sans-serif;
}

@media print, screen and (min-width: 980px) {
  body {
    font-size: 14px;
  }
}

html > body {
  min-width: 300px;
}

@media print, screen and (min-width: 980px) {
  html > body {
    min-width: 1240px;
  }
}

a {
  color: #cb4557;
  outline: none;
  -webkit-touch-callout: none;
}

a:hover {
  text-decoration: none;
}

a:focus,
*:focus {
  outline: none;
}

img {
  border: none;
  border-style: none;
  vertical-align: top;
}

ol,
ul,
li {
  list-style: none;
}

/* */
br.SPbr {
  display: inline;
}

@media print, screen and (min-width: 980px) {
  br.SPbr {
    display: none;
  }
}

br.PCbr {
  display: none;
}

@media print, screen and (min-width: 980px) {
  br.PCbr {
    display: inline;
  }
}

.SPview {
  display: block;
}

@media print, screen and (min-width: 980px) {
  .SPview {
    display: none;
  }
}

.PCview {
  display: none;
}

@media print, screen and (min-width: 980px) {
  .PCview {
    display: block;
  }
}

.u-textCenter {
  text-align: left;
}

.u-textLeft {
  text-align: left;
}

.u-textRight {
  text-align: right;
}

.u-floatCenter {
  float: left;
}

.u-floatLeft {
  float: left;
}

.u-floatRight {
  float: right;
}

/* */
.l-toggle,
.taglive_contents_tile .modal_wrap {
  z-index: 999999;
}

.l-toggleWrap,
.taglive_modal_overlay {
  z-index: 99999;
}

.c-kyoe,
.c-img__kyoe,
.c-img__kyoeSP {
  z-index: 9999;
}

.c-share {
  z-index: 10;
}

.l-footer {
  z-index: 5;
}

/* ==========================================================================
   Layout Object
   ========================================================================== */
/* */
.c-btn a, .c-btn.-more {
  display: block;
  color: #fff;
  text-decoration: none;
  background: #cb4557;
  border: 3px solid #cb4557;
  color: #fff;
  text-align: center;
  font-size: 13px;
  border-radius: 28px;
  line-height: 1;
}

@media print, screen and (min-width: 980px) {
  .c-btn a, .c-btn.-more {
    display: inline-block;
    margin: 0 auto;
    padding: 18px 25px;
  }
}

.c-btn a {
  padding: 18px 30px;
  position: relative;
}

@media print, screen and (min-width: 980px) {
  .c-btn a {
    transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    opacity: 1;
  }
  .c-btn a:hover {
    opacity: .7;
  }
  .c-release__item.-release2 .c-btn a {
    display: block;
    padding: 18px 0;
  }
}

.c-btn.-more {
  padding: 18px 5px;
  background: #fff;
  cursor: pointer;
  color: #cb4557;
  margin-top: 22px;
}

@media print, screen and (min-width: 980px) {
  .c-btn.-more {
    margin-top: 25px;
    display: block;
    width: 240px;
    transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .c-btn.-more:hover {
    background: #cb4557;
    color: #fff;
    opacity: .7;
  }
}

/* */
.c-card {
  background: #fff;
  border-radius: 10px;
  padding: 40px 20px;
  margin-bottom: 20px;
}

@media print, screen and (min-width: 980px) {
  .c-card {
    border-radius: 20px;
    padding: 40px;
    margin-bottom: 40px;
  }
}

.c-card .c-release__title {
  font-size: 10px;
  line-height: 1;
  color: #878787;
}

@media print, screen and (min-width: 980px) {
  .c-card .c-release__title {
    font-size: 12px;
  }
}

.c-card .c-release__item {
  border-bottom: 1px solid #e9e9e9;
  padding: 20px 0;
}

.c-card .c-release__item:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
}

@media print, screen and (min-width: 980px) {
  .c-card .c-release__item {
    padding: 30px 0 25px;
  }
  .c-card .c-release__item:first-of-type {
    padding-top: 20px;
  }
}

.c-card .c-release__item h3 {
  font-size: 15px;
  line-height: 1.86667;
  margin-bottom: 5px;
  color: #ce6a77;
  letter-spacing: 0.03em;
}

.c-card .c-release__item h3::before {
  content: '';
  display: block;
  height: 0;
  width: 0;
  margin-top: calc((1 - 1.86667) * 0.5em);
}

@media print, screen and (min-width: 980px) {
  .c-card .c-release__item h3 {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 1.77778;
  }
  .c-card .c-release__item h3::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((1 - 1.77778) * 0.5em);
  }
}

.c-card .c-release__item h4 {
  line-height: 1.69231;
  margin-bottom: 10px;
  color: #ce6a77;
  letter-spacing: 0.03em;
}

.c-card .c-release__item h4::before {
  content: '';
  display: block;
  height: 0;
  width: 0;
  margin-top: calc((1 - 1.69231) * 0.5em);
}

@media print, screen and (min-width: 980px) {
  .c-card .c-release__item h4 {
    line-height: 1.57143;
  }
  .c-card .c-release__item h4::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((1 - 1.57143) * 0.5em);
  }
}

.c-card .c-release__item .img img {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}

@media print, screen and (min-width: 980px) {
  .c-card .c-release .-release3 h3 {
    margin-bottom: 5px;
  }
  .c-card .c-release .-release3 h4 {
    margin-bottom: 0;
  }
}

.c-card .c-release .-release3 p {
  margin-bottom: 20px;
}

.c-card .c-release .-release3 ol li {
  text-indent: -1.5em;
  margin-left: 1.5em;
}

.c-card .c-release .-release3 ol li:nth-child(1):before {
  content: "1：";
}

.c-card .c-release .-release3 ol li:nth-child(2):before {
  content: "2：";
}

.c-card .c-release .-release3 ol li:nth-child(3):before {
  content: "3：";
}

.c-card .c-release .-release3 ol li:nth-child(4):before {
  content: "4：";
}

.c-card .c-release .-release3 ol li:nth-child(5):before {
  content: "5：";
}

.c-card .c-release .-release3 ol li:nth-child(6):before {
  content: "6：";
}

.c-card .c-release .-release3 ol li:nth-child(7):before {
  content: "7：";
}

.c-card .c-release .-release3 ol li:nth-child(8):before {
  content: "8：";
}

.c-card .c-release .-release3 ol li:nth-child(9):before {
  content: "9：";
}

.c-card .c-release .-release3 ol li:nth-child(10):before {
  content: "10：";
}

.c-card .c-release .-release3 ol li:nth-child(11):before {
  content: "11：";
}

.c-card .c-release .-release3 ol li:nth-child(12):before {
  content: "12：";
}

.c-card .c-release .-release3 ol li:nth-child(13):before {
  content: "13：";
}

.c-card .c-release .-release3 ol li:nth-child(14):before {
  content: "14：";
}

.c-card .c-release .-release3 ol li:nth-child(15):before {
  content: "15：";
}

.c-card .c-release .-release3 ol li:nth-child(16):before {
  content: "16：";
}

.c-card .c-release .-release3 ol li:nth-child(17):before {
  content: "17：";
}

.c-card .c-release .-release3 ol li:nth-child(18):before {
  content: "18：";
}

.c-card .c-release .-release3 ol li:nth-child(19):before {
  content: "19：";
}

.c-card .c-release .-release3 ol li:nth-child(20):before {
  content: "20：";
}

.c-card .c-release .-release3 ol li:nth-child(21):before {
  content: "21：";
}

.c-card .c-release .-release3 ol li:nth-child(22):before {
  content: "22：";
}

.c-card .c-release .-release3 ol li:nth-child(23):before {
  content: "23：";
}

.c-card .c-release .-release3 ol li:nth-child(24):before {
  content: "24：";
}

.c-card .c-release .-release3 ol li:nth-child(25):before {
  content: "25：";
}

.c-card .c-release .-release3 ol li:nth-child(26):before {
  content: "26：";
}

.c-card .c-release .-release3 ol li:nth-child(27):before {
  content: "27：";
}

.c-card .c-release .-release3 ol li:nth-child(28):before {
  content: "28：";
}

.c-card .c-release .-release3 ol li:nth-child(29):before {
  content: "29：";
}

.c-card .c-release .-release3 ol li:nth-child(30):before {
  content: "30：";
}

.c-card .c-release .-release2 {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 auto;
}

.c-card .c-release .-release2 .release2__wrap {
  display: contents;
}

@media print, screen and (min-width: 980px) {
  .c-card .c-release .-release2 .release2__wrap {
    display: block;
    float: left;
    width: 160px;
  }
}

.c-card .c-release .-release2 .release2__1 {
  order: 1;
  width: 100%;
}

.c-card .c-release .-release2 .release2__2 {
  order: 3;
  width: 100%;
}

.c-card .c-release .-release2 .release2__3 {
  order: 2;
  width: 100%;
}

.c-card .c-release .-release2 .release2__4 {
  order: 4;
  width: 100%;
}

.c-card .c-release .-release2 .release2__4 .c-btn {
  margin-bottom: 35px;
}

.c-card .c-release .-release2 .release2__5 {
  order: 5;
  width: 100%;
}

.c-card .c-release .-release2 .release2__1,
.c-card .c-release .-release2 .release2__2 {
  margin-bottom: 20px;
}

.c-card .c-release .-release2 .release2__1 figure,
.c-card .c-release .-release2 .release2__2 figure {
  width: 37.3%;
  margin: 0 auto;
}

.c-card .c-release .-release2 .release2__1 img,
.c-card .c-release .-release2 .release2__2 img {
  width: 100%;
  height: auto;
}

.c-card .c-release .-release2 .release2__1Caption,
.c-card .c-release .-release2 .release2__2Caption {
  text-align: center;
  color: #878787;
  font-size: 10px;
  margin-top: 10px;
}

@media print, screen and (min-width: 980px) {
  .c-card .c-release .-release2 .release2__1Caption,
  .c-card .c-release .-release2 .release2__2Caption {
    font-size: 12px;
  }
}

.c-card .c-release .-release2 .release2__5 .c-btn {
  margin-bottom: 40px;
}

.c-card .c-release .-release2 .release2__5 h4 {
  margin-bottom: 5px;
}

.c-card .c-release .-release2 .release2__5 .notice {
  font-size: 11px;
  margin-bottom: 5px;
}

@media print, screen and (min-width: 980px) {
  .c-card .c-release .-release2 {
    display: block;
  }
  .c-card .c-release .-release2:after {
    display: block;
    clear: both;
    content: "";
  }
  .c-card .c-release .-release2 .release2__1 figure,
  .c-card .c-release .-release2 .release2__2 figure {
    width: 100%;
  }
  .c-card .c-release .-release2 .release2__1 img,
  .c-card .c-release .-release2 .release2__2 img {
    width: 100%;
    height: auto;
  }
  .c-card .c-release .-release2 .release2__1 {
    margin-bottom: 30px;
  }
  .c-card .c-release .-release2 .release2__3,
  .c-card .c-release .-release2 .release2__4 {
    padding-left: 200px;
  }
  .c-card .c-release .-release2 .release2__5 {
    clear: both;
  }
  .c-card .c-release .-release2 .release2__5:after {
    display: block;
    clear: both;
    content: "";
  }
  .c-card .c-release .-release2 .release2__5 .c-btn {
    float: left;
    width: 160px;
    margin-bottom: 0;
  }
  .c-card .c-release .-release2 .release2__5 .c-btn a {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .c-card .c-release .-release2 .release2__5 h4 {
    margin-bottom: 5px;
    padding-left: 200px;
  }
  .c-card .c-release .-release2 .release2__5 .img {
    padding-left: 200px;
  }
  .c-card .c-release .-release2 .release2__5 .notice {
    padding-left: 200px;
    font-size: 12px;
    margin-bottom: 5px;
  }
}

.c-card .c-release .-release2 h4 {
  font-size: 15px;
  line-height: 1.86667;
  margin-bottom: 15px;
  letter-spacing: 0.03em;
  color: #1c1c1c;
}

.c-card .c-release .-release2 h4::before {
  content: '';
  display: block;
  height: 0;
  width: 0;
  margin-top: calc((1 - 1.86667) * 0.5em);
}

@media print, screen and (min-width: 980px) {
  .c-card .c-release .-release2 h4 {
    font-size: 18px;
    line-height: 1.77778;
  }
  .c-card .c-release .-release2 h4::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((1 - 1.77778) * 0.5em);
  }
}

.c-card .c-release .-release2 h4 b {
  color: #ce6a77;
}

.c-card .c-release .-release2 h5 {
  font-size: 13px;
  line-height: 1.69231;
  margin-bottom: 10px;
  letter-spacing: 0.03em;
}

.c-card .c-release .-release2 h5::before {
  content: '';
  display: block;
  height: 0;
  width: 0;
  margin-top: calc((1 - 1.69231) * 0.5em);
}

@media print, screen and (min-width: 980px) {
  .c-card .c-release .-release2 h5 {
    font-size: 14px;
    line-height: 1.57143;
    margin-bottom: 0;
  }
  .c-card .c-release .-release2 h5::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((1 - 1.57143) * 0.5em);
  }
}

.c-card .c-release .-release2 ul {
  margin-bottom: 35px;
}

@media print, screen and (min-width: 980px) {
  .c-card .c-release .-release2 ul {
    margin-bottom: 20px;
  }
}

.c-card .c-release .-release2 ul li {
  text-indent: -1em;
  margin-left: 1em;
}

.c-card .c-release .-release1 {
  display: flex;
  flex-wrap: wrap;
}

.c-card .c-release .-release1 .c-release__img {
  order: 3;
  width: 100%;
}

.c-card .c-release .-release1 .c-release__img span {
  display: block;
  width: 37.3%;
  margin: 40px auto;
}

.c-card .c-release .-release1 .c-release__img img {
  width: 100%;
  height: auto;
}

.c-card .c-release .-release1 h3 {
  order: 1;
}

.c-card .c-release .-release1 ul {
  order: 2;
}

.c-card .c-release .-release1 .c-btn {
  order: 4;
  width: 100%;
}

.c-card .c-release .-release1 .c-btn a {
  text-align: center;
}

@media print, screen and (min-width: 980px) {
  .c-card .c-release .-release1 {
    display: block;
  }
  .c-card .c-release .-release1:after {
    display: block;
    clear: both;
    content: "";
  }
  .c-card .c-release .-release1 .c-release__img {
    float: left;
    width: 160px;
    margin: 0;
  }
  .c-card .c-release .-release1 .c-release__img span {
    width: 100%;
    margin: 0;
  }
  .c-card .c-release .-release1 .c-release__img img {
    width: 100%;
    height: auto;
  }
  .c-card .c-release .-release1 h3,
  .c-card .c-release .-release1 ul,
  .c-card .c-release .-release1 .c-btn {
    padding-left: 200px;
  }
  .c-card .c-release .-release1 .c-btn {
    margin-top: 20px;
  }
}

.c-card .c-prifile__title {
  font-size: 10px;
  line-height: 1;
  color: #878787;
  margin-bottom: 14px;
}

@media print, screen and (min-width: 980px) {
  .c-card .c-prifile__title {
    font-size: 12px;
    margin-bottom: 30px;
  }
}

.c-card .c-prifile__img span {
  display: block;
}

.c-card .c-prifile__img span img {
  width: 100%;
  height: auto;
}

.c-card .c-prifile__caption {
  display: block;
  font-size: 15px;
  color: #ce6a77;
  margin-top: 26px;
}

@media print, screen and (min-width: 980px) {
  .c-card .c-prifile__caption {
    font-size: 18px;
    margin-top: 30px;
  }
}

.c-card .c-prifile__text {
  margin-top: 20px;
  line-height: 1.69231;
}

@media print, screen and (min-width: 980px) {
  .c-card .c-prifile__text {
    margin-top: 23px;
    line-height: 1.57143;
  }
}

.c-card .c-movie__title {
  font-size: 10px;
  line-height: 1;
  color: #878787;
  margin-bottom: 14px;
}

@media print, screen and (min-width: 980px) {
  .c-card .c-movie__title {
    font-size: 12px;
    margin-bottom: 30px;
  }
}

.c-card .c-movie__body iframe {
  width: 100%;
  height: 100%;
}

@media print, screen and (min-width: 980px) {
  .c-card .c-movie__body iframe {
    width: 620px;
    height: 348px;
  }
}

.c-card .c-lyric__title {
  font-size: 10px;
  line-height: 1;
  color: #878787;
  margin-bottom: 14px;
}

@media print, screen and (min-width: 980px) {
  .c-card .c-lyric__title {
    font-size: 12px;
    margin-bottom: 30px;
  }
}

.c-card .c-lyric__img span img {
  width: 100%;
  height: auto;
}

@media print, screen and (min-width: 980px) {
  .c-card .c-lyric__img span img {
    width: 560px;
    margin: 0 auto;
  }
}

.c-card .c-lyric__caption {
  display: block;
  font-size: 15px;
  color: #ce6a77;
  margin-top: 26px;
}

@media print, screen and (min-width: 980px) {
  .c-card .c-lyric__caption {
    font-size: 18px;
    margin-top: 30px;
  }
}

.c-card .c-lyric__text {
  margin-top: 20px;
  line-height: 1.69231;
}

@media print, screen and (min-width: 980px) {
  .c-card .c-lyric__text {
    margin-top: 23px;
    line-height: 1.57143;
  }
}

@media print, screen and (min-width: 980px) {
  .c-card__wrap:after {
    display: block;
    clear: both;
    content: "";
  }
  .c-card__wrap #Twitter {
    float: left;
    width: calc(50% - 10px);
    box-sizing: border-box;
  }
  .c-card__wrap #Instagram,
  .c-card__wrap #Profile {
    float: right;
    width: calc(50% - 10px);
    margin-bottom: 20px;
    box-sizing: border-box;
  }
}

/* */
.c-img__kyoe {
  display: none;
  position: fixed;
  width: 42%;
  bottom: -86px;
  pointer-events: all;
  left: 4vw;
  max-width: 160px;
}

.c-img__kyoe::after {
  content: "";
  display: block;
  background: url("../img/common/img_character_title.png") no-repeat center;
  background-size: 100% auto;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.c-img__kyoe img {
  width: 100%;
  height: auto;
}

@media print, screen and (min-width: 980px) {
  .c-img__kyoe {
    display: block;
    width: 440px;
    left: 10vw;
    max-width: 440px;
    top: 20px;
    bottom: inherit;
  }
}

.c-img__kyoeSP {
  position: fixed;
  width: 42%;
  pointer-events: all;
  left: 4vw;
  bottom: 0;
  transition: transform 0.3s cubic-bezier(0.55, 0.09, 0.68, 0.53);
  transform: translateY(0);
  max-width: 160px;
}

@media print, screen and (min-width: 980px) {
  .c-img__kyoeSP {
    display: none;
  }
}

.c-img__kyoeSP::after {
  content: "";
  display: block;
  background: url("../img/common/img_character_title.png") no-repeat center;
  background-size: 100% auto;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.c-img__kyoeSP img {
  width: 100%;
  height: auto;
}

.c-img__kyoeSP.-hidden {
  transform: translateY(86px);
}

/* */
.c-kyoe {
  position: fixed;
  pointer-events: all;
  left: 1vw;
  bottom: 0;
  width: calc(400vw / 7.68);
  height: calc(668vw / 7.68);
  -webkit-tap-highlight-color: transparent;
  /* animation */
}

@media print, screen and (min-width: 980px) {
  .c-kyoe {
    left: 10vw;
    top: 20px;
    width: 440px;
    height: 100%;
    bottom: inherit;
  }
}

.c-kyoe #kyoe, .c-kyoe #kyoe > * {
  position: absolute;
}

.c-kyoe #kyoe > * {
  background: transparent 50% / cover no-repeat;
}

.c-kyoe #kyoe {
  top: 50%;
  left: 50%;
  width: calc(352.5vw / 7.68);
  /* ← MEMO: 画面幅 1440px 時点で画像の横幅が 705px になる計算 */
  height: calc(668vw / 7.68);
  transform: translate(-50%, -50%);
}

.c-kyoe #kyoe .body {
  width: calc(400vw / 7.68);
  height: calc(668vw / 7.68);
}

.c-kyoe #kyoe .eye {
  top: calc(136vw / 7.68);
  left: calc(32vw / 7.68);
  width: calc(290vw / 7.68);
  height: calc(80vw / 7.68);
}

.c-kyoe #kyoe .l-wing {
  top: calc(354vw / 7.68);
  right: calc(10vw / 7.68);
  width: calc(79.5vw / 7.68);
  height: calc(190vw / 7.68);
}

.c-kyoe #kyoe .r-wing {
  top: calc(354vw / 7.68);
  left: calc(10vw / 7.68);
  width: calc(78.5vw / 7.68);
  height: calc(185vw / 7.68);
}

@media screen and (min-width: 768px) {
  .c-kyoe #kyoe {
    width: calc(352.5px * 1.1);
    height: calc(668px * 1.1);
  }
  .c-kyoe #kyoe .body {
    width: calc(400px * 1.1);
    height: calc(668px * 1.1);
  }
  .c-kyoe #kyoe .eye {
    top: calc(136px * 1.1);
    left: calc(32px * 1.1);
    width: calc(290px * 1.1);
    height: calc(80px * 1.1);
  }
  .c-kyoe #kyoe .l-wing {
    top: calc(354px * 1.1);
    right: calc(10px * 1.1);
    width: calc(79.5px * 1.1);
    height: calc(190px * 1.1);
  }
  .c-kyoe #kyoe .r-wing {
    top: calc(354px * 1.1);
    left: calc(10px * 1.1);
    width: calc(78.5px * 1.1);
    height: calc(185px * 1.1);
  }
}

.c-kyoe #kyoe .body {
  background-image: url(../img/common/kyoe_body.png);
}

.c-kyoe #kyoe .eye {
  background-image: url(../img/common/kyoe_eye.png);
  background-position: 50% 0;
}

.c-kyoe #kyoe .l-wing {
  background-image: url(../img/common/kyoe_l_wing.png);
  transform-origin: 20% 20%;
}

.c-kyoe #kyoe .r-wing {
  background-image: url(../img/common/kyoe_r_wing.png);
  transform-origin: 80% 20%;
}

.c-kyoe #kyoe {
  transition: transform 0.16s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  /* easeInCubic */
}

.c-kyoe #kyoe.blink1 .eye {
  animation: blink-1 0.3s steps(1);
}

.c-kyoe #kyoe.blink2 .eye {
  animation: blink-2 0.3s steps(1);
}

.c-kyoe #kyoe.flip {
  transform: translate(-50%, -52%);
  transition-duration: 1.6s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  /* easeInOutCubic */
}

.c-kyoe #kyoe.flip .l-wing {
  animation: l-flip 1.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  /* easeOutCubic */
}

.c-kyoe #kyoe.flip .r-wing {
  animation: r-flip 1.6s cubic-bezier(0.645, 0.045, 0.355, 1);
}

@keyframes blink-1 {
  0%, 100% {
    background-position: 50% 0;
  }
  16% {
    background-position: 50% 25%;
  }
  33% {
    background-position: 50% 100%;
  }
  50% {
    background-position: 50% 75%;
  }
  66% {
    background-position: 50% 25%;
  }
  84% {
    background-position: 50% 0;
  }
}

@keyframes blink-2 {
  0%, 100% {
    background-position: 50% 0;
  }
  14% {
    background-position: 50% 25%;
  }
  28% {
    background-position: 50% 100%;
  }
  42% {
    background-position: 50% 75%;
  }
  58% {
    background-position: 50% 50%;
  }
  72% {
    background-position: 50% 25%;
  }
  86% {
    background-position: 50% 0;
  }
}

@keyframes l-flip {
  0%, 100% {
    transform: none;
  }
  8% {
    transform: rotate(-120deg);
  }
  16% {
    transform: rotate(-20deg);
  }
  25% {
    transform: rotate(-130deg);
  }
  31% {
    transform: rotate(-30deg);
  }
  36% {
    transform: rotate(-140deg);
  }
  40% {
    transform: rotate(-30deg);
  }
  44% {
    transform: rotate(-140deg);
  }
  48% {
    transform: rotate(-30deg);
  }
  52% {
    transform: rotate(-140deg);
  }
  56% {
    transform: rotate(-30deg);
  }
  60% {
    transform: rotate(-140deg);
  }
  64% {
    transform: rotate(-30deg);
  }
  68% {
    transform: rotate(-140deg);
  }
  72% {
    transform: rotate(-30deg);
  }
  76% {
    transform: rotate(-140deg);
  }
  81% {
    transform: rotate(-20deg);
  }
  87% {
    transform: rotate(-130deg);
  }
  94% {
    transform: rotate(-90deg);
    animation-timing-function: cubic-bezier(0.3, 0.9, 0.6, 1.1);
  }
}

@keyframes r-flip {
  0%, 100% {
    transform: none;
  }
  8% {
    transform: rotate(120deg);
  }
  16% {
    transform: rotate(20deg);
  }
  25% {
    transform: rotate(130deg);
  }
  31% {
    transform: rotate(30deg);
  }
  36% {
    transform: rotate(140deg);
  }
  40% {
    transform: rotate(30deg);
  }
  44% {
    transform: rotate(140deg);
  }
  48% {
    transform: rotate(30deg);
  }
  52% {
    transform: rotate(140deg);
  }
  56% {
    transform: rotate(30deg);
  }
  60% {
    transform: rotate(140deg);
  }
  64% {
    transform: rotate(30deg);
  }
  68% {
    transform: rotate(140deg);
  }
  72% {
    transform: rotate(30deg);
  }
  76% {
    transform: rotate(140deg);
  }
  81% {
    transform: rotate(20deg);
  }
  87% {
    transform: rotate(130deg);
  }
  94% {
    transform: rotate(90deg);
    animation-timing-function: cubic-bezier(0.3, 0.9, 0.6, 1.1);
  }
}

/* */
.modal .c-modal__img {
  margin-bottom: 30px;
}

@media print, screen and (min-width: 980px) {
  .modal .c-modal__img {
    width: 330px;
    margin-bottom: 40px;
  }
}

.modal .c-modal__text {
  font-size: 15px;
  line-height: 2;
}

@media print, screen and (min-width: 980px) {
  .modal .c-modal__text {
    font-size: 18px;
    line-height: 2.22222;
  }
}

.modal .c-modal__text span {
  color: #cb4557;
}

/* */
.c-share {
  padding-left: 4vw;
  bottom: 36px;
  position: relative;
  top: -20px;
}

@media print, screen and (min-width: 980px) {
  .c-share {
    position: fixed;
    bottom: 24px;
    left: 3.1vw;
    padding: 0;
    top: auto;
  }
}

.c-share__title {
  display: inline-block;
  white-space: nowrap;
  color: #f9dade;
  font-size: 11px;
  margin-right: 12px;
  letter-spacing: 0.1em;
}

@media print, screen and (min-width: 980px) {
  .c-share__title {
    display: block;
    position: absolute;
    top: -46px;
    margin-right: 3px;
    margin-bottom: 20px;
    font-feature-settings: "vpal","vert","vkrn";
    writing-mode: vertical-rl;
    text-orientation: upright;
  }
}

.c-share__list {
  display: inline-block;
  white-space: nowrap;
}

@media print, screen and (min-width: 980px) {
  .c-share__list {
    display: block;
  }
}

.c-share__item {
  margin-right: 17px;
  display: inline-block;
}

.c-share__item:last-of-type {
  margin-right: 0;
}

@media print, screen and (min-width: 980px) {
  .c-share__item {
    display: block;
    margin-right: 0;
    margin-bottom: 16px;
  }
}

.c-share__item a {
  display: block;
}

.c-share__item a img {
  vertical-align: middle;
}

.c-share__item.-twitter img {
  width: 20px;
  height: 16px;
}

@media print, screen and (min-width: 980px) {
  .c-share__item.-twitter img {
    width: 18px;
    height: 15px;
  }
}

.c-share__item.-facebook {
  width: 20px;
  height: 20px;
}

@media print, screen and (min-width: 980px) {
  .c-share__item.-facebook img {
    width: 18px;
    height: 18px;
  }
}

.c-share__item.-line img {
  width: 20px;
  height: 20px;
}

@media print, screen and (min-width: 980px) {
  .c-share__item.-line img {
    width: 18px;
    height: 18px;
  }
}

/* */
@media print, screen and (min-width: 980px) {
  .c-sns {
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
  }
}

.c-sns__title {
  font-size: 10px;
  line-height: 1;
  color: #878787;
  margin-bottom: 14px;
}

@media print, screen and (min-width: 980px) {
  .c-sns__title {
    font-size: 12px;
    margin-bottom: 20px;
  }
}

.c-sns__twitter {
  width: 100%;
  border: 1px solid #e9e9e9;
}

@media print, screen and (min-width: 980px) {
  .c-sns__twitter {
    margin-bottom: 0;
  }
}

.c-sns__twitter #twitter-widget-0 {
  width: 100% !important;
}

@media print, screen and (min-width: 980px) {
  .c-sns__twitter #twitter-widget-0 {
    width: 290px;
  }
}

.c-sns #instafeed {
  width: 100%;
  border: 1px solid #e9e9e9;
}

@media print, screen and (min-width: 980px) {
  .c-sns #instafeed {
    width: 260px;
  }
}

.c-sns #instafeed .item {
  width: 100%;
}

.c-sns #instafeed a {
  display: block;
  height: 100%;
  background: center / cover no-repeat;
  transition: opacity .2s;
}

.c-sns #instafeed a:hover {
  opacity: .8;
}

.c-sns #instafeed img {
  width: 100%;
}

/* */
@media print, screen and (min-width: 980px) {
  .c-taglive {
    margin-bottom: 20px;
  }
  .c-taglive:after {
    display: block;
    clear: both;
    content: "";
  }
}

.c-taglive__item {
  border: 3px solid #000;
  border-radius: 3px;
  background: #fff;
  margin-bottom: 20px;
}

@media print, screen and (min-width: 980px) {
  .c-taglive__item {
    border: 6px solid #000;
    width: calc(50% - 10px);
    box-sizing: border-box;
    margin-bottom: 0;
  }
  .c-taglive__item:nth-of-type(odd) {
    float: left;
  }
  .c-taglive__item:nth-of-type(even) {
    float: right;
  }
}

.c-taglive__title {
  border-bottom: 5px solid #000;
}

@media print, screen and (min-width: 980px) {
  .c-taglive__title {
    border-bottom: 6px solid #000;
  }
}

.c-taglive__title img {
  width: 100%;
  height: auto;
}

@media print, screen and (min-width: 980px) {
  .c-taglive__inner {
    height: 680px;
    overflow-y: auto;
  }
}

.c-taglive .taglive_contents_tile {
  width: 100% !important;
  box-sizing: border-box;
  margin: 0 auto 34px !important;
}

@media (min-width: 768px) and (max-width: 980px) {
  .c-taglive .taglive_contents_tile {
    width: 96% !important;
  }
}

@media print, screen and (min-width: 980px) {
  .c-taglive .taglive_contents_tile {
    width: 288px !important;
    margin: 0 0 0 21px !important;
  }
}

.c-taglive .taglive_contents_tile .post {
  border-radius: 0;
  margin-left: 16px;
  margin-bottom: 16px;
}

@media print, screen and (min-width: 980px) {
  .c-taglive .taglive_contents_tile .post {
    width: 125px;
    margin-left: 10px;
    margin-bottom: 12px;
  }
}

.c-taglive .taglive_contents_tile .post {
  width: 41% !important;
}

@media (min-width: 768px) and (max-width: 980px) {
  .c-taglive .taglive_contents_tile .post {
    width: 44% !important;
  }
}

@media print, screen and (min-width: 980px) {
  .c-taglive .taglive_contents_tile .post {
    width: 44% !important;
  }
}

.c-taglive .taglive_contents_tile .inst_photo, .c-taglive .taglive_contents_tile .twitter_photo {
  width: 36vw !important;
  height: 36vw;
  background: #fff;
}

@media (min-width: 768px) and (max-width: 980px) {
  .c-taglive .taglive_contents_tile .inst_photo, .c-taglive .taglive_contents_tile .twitter_photo {
    width: 39vw !important;
    height: 39vw;
  }
}

@media print, screen and (min-width: 980px) {
  .c-taglive .taglive_contents_tile .inst_photo, .c-taglive .taglive_contents_tile .twitter_photo {
    width: 126px !important;
    height: 126px !important;
  }
}

.c-taglive .taglive_img_info {
  width: 100%;
  box-sizing: border-box;
  padding-right: 20px;
  padding-top: 14px;
  padding-bottom: 8px;
}

.c-taglive .taglive_img_info a, .c-taglive .taglive_img_info img {
  width: 17px;
  height: auto;
}

.c-taglive .taglive_next_button_tile {
  margin: 0 auto 34px;
}

.c-taglive .c-btn.-more {
  margin: 0 10px 20px;
}

@media print, screen and (min-width: 980px) {
  .c-taglive .c-btn.-more {
    margin: 34px auto 0;
  }
}

.taglive_modal_overlay {
  background: rgba(220, 133, 144, 0.9);
}

.c-taglive .taglive_contents_tile .post {
  border: none;
}

.taglive_contents_tile .taglive_entry_text {
  padding: 0;
}

.taglive_contents_tile .modal_meta a {
  color: #878787;
  text-decoration: none;
}

.taglive_contents_tile .inst_profile {
  background: url(../img/common/icon_instagram.svg) no-repeat 2px 0.5em;
  background-size: 16px auto;
}

.taglive_contents_tile .twitter_profile {
  background: url(../img/common/icon_twitter_gry.svg) no-repeat 2px 0.5em;
  background-size: 16px auto;
}

.taglive_contents_tile .modal_meta_inst {
  background: url(../img/common/icon_instagram.svg) no-repeat right 10px;
  background-size: 16px auto;
}

.taglive_contents_tile .modal_meta_twitter {
  background: url(../img/common/icon_twitter_gry.svg) no-repeat right 10px;
  background-size: 16px auto;
}

.taglive_contents_tile .modal_content {
  width: 70%;
}

@media screen and (max-width: 767px) {
  .taglive_contents_tile .modal_content {
    top: 78px !important;
  }
}

@media print, screen and (min-width: 980px) {
  .taglive_contents_tile .modal_content {
    width: 800px;
  }
}

.taglive_contents_tile .modal_profile a.modal_user,
.taglive_contents_tile .twitter_profile .profile_txt a, .taglive_contents_tile .inst_profile .profile_txt a {
  color: #ce6a77;
}

@media screen and (max-width: 767px) {
  .taglive_contents_tile .modal_change {
    top: 102%;
  }
}

.taglive_contents_tile .modal_change.prev {
  background-image: url(../img/common/arrow.svg);
  transform: rotate(180deg);
  left: 0;
}

@media print, screen and (min-width: 768px) {
  .taglive_contents_tile .modal_change.prev {
    left: -55px;
  }
}

.taglive_contents_tile .modal_change.next {
  background-image: url(../img/common/arrow.svg);
  right: 0;
}

@media print, screen and (min-width: 768px) {
  .taglive_contents_tile .modal_change.next {
    right: -55px;
  }
}

.taglive_contents_tile .modal_close {
  position: fixed;
  top: 20px;
  right: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: #cb4557;
  border-radius: 50%;
}

@media print, screen and (min-width: 980px) {
  .taglive_contents_tile .modal_close {
    width: 58px;
    height: 58px;
    right: 28px;
  }
}

.taglive_contents_tile .modal_close::before, .taglive_contents_tile .modal_close::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 19px;
  height: 3px;
  pointer-events: none;
  content: "";
  content: '';
  background: #fff;
  border-radius: 1.5px;
}

@media print, screen and (min-width: 980px) {
  .taglive_contents_tile .modal_close::before, .taglive_contents_tile .modal_close::after {
    width: 24px;
  }
}

.taglive_contents_tile .modal_close::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.taglive_contents_tile .modal_close::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

/* */
.text__baka {
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.55, 0.09, 0.68, 0.53);
  position: fixed;
  font-size: 10px;
  color: #f9dade;
  text-align: center;
  top: 83px;
  right: 35px;
  transform: scale(0.9);
}

@media print, screen and (min-width: 980px) {
  .l-header .text__baka {
    opacity: 0;
    z-index: 999;
  }
  .l-header.is-fixed .text__baka {
    opacity: 1;
  }
}

.l-toggle .text__baka {
  z-index: 999999;
  opacity: 1;
}

@media print, screen and (min-width: 768px) {
  .text__baka {
    top: 100px;
    right: 50px;
    font-size: 11px;
    transform: scale(1);
  }
}

.text__baka i {
  display: block;
  position: relative;
}

.text__baka i::before {
  content: "";
  width: 1px;
  height: 40px;
  display: block;
  background: #f9dade;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin: 2px 0;
}

@media print, screen and (min-width: 768px) {
  .text__baka i::before {
    width: 1px;
    height: 80px;
  }
}

.scroll__baka {
  transition: opacity 0.2s cubic-bezier(0.55, 0.09, 0.68, 0.53);
  letter-spacing: .1em;
  position: relative;
  font-size: 10px;
  color: #1c1c1c;
  text-align: right;
  top: -25px;
  right: -5%;
  transform: scale(0.9);
}

.l-header .scroll__baka {
  display: none;
}

@media print, screen and (min-width: 768px) {
  .l-header .scroll__baka {
    display: block;
  }
}

@media print, screen and (min-width: 768px) {
  .scroll__baka {
    font-feature-settings: "vpal","vert","vkrn";
    writing-mode: vertical-rl;
    text-orientation: upright;
  }
  .l-main .scroll__baka {
    display: none;
  }
}

.l-header .scroll__baka {
  opacity: 1;
}

.l-header.is-fixed .scroll__baka {
  opacity: 0;
}

@media print, screen and (min-width: 768px) {
  .scroll__baka {
    position: fixed;
    top: 544px;
    bottom: 30px;
    right: 46px;
    font-size: 11px;
    transform: scale(1);
    text-align: center;
  }
}

.scroll__baka i {
  display: inline-block;
  position: relative;
}

.scroll__baka i::before {
  content: "";
  width: 40px;
  height: 1px;
  display: inline-block;
  background: #1c1c1c;
  position: relative;
  margin: 0 5px;
  vertical-align: middle;
}

@media print, screen and (min-width: 768px) {
  .scroll__baka i::before {
    width: 1px;
    height: 80px;
    margin: 5px 0;
    vertical-align: inherit;
  }
}

/* */
.l-contents {
  padding: 0 4vw;
  z-index: 5;
}

@media print, screen and (min-width: 980px) {
  .l-contents {
    position: relative;
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 10px;
    position: static;
  }
  .l-contents:after {
    display: block;
    clear: both;
    content: "";
  }
}

.l-contents .l-main {
  margin-top: -33px;
  padding-bottom: 72px;
}

@media print, screen and (min-width: 980px) {
  .l-contents .l-main {
    padding-top: 60px;
    padding-bottom: 80px;
    float: right;
    width: 700px;
    margin-top: 0;
  }
}

/* */
.l-footer {
  position: relative;
  background: #ce6a77;
}

.l-footer__inner {
  padding: 40px 0 calc(668vw / 7.68);
}

@media print, screen and (min-width: 980px) {
  .l-footer__inner {
    max-width: 1240px;
    margin: 0 auto;
    padding: 60px 0 54px;
  }
  .l-footer__inner:after {
    display: block;
    clear: both;
    content: "";
  }
}

.l-footer-bnr__list {
  margin-bottom: 25px;
  text-align: center;
}

@media print, screen and (min-width: 980px) {
  .l-footer-bnr__list {
    float: right;
    width: 700px;
    margin-bottom: 40px;
  }
}

.l-footer-bnr__item {
  display: inline-block;
  width: 265px;
}

.l-footer-bnr__item a {
  display: block;
}

.l-footer-bnr__item img {
  width: 100%;
  height: auto;
}

@media print, screen and (min-width: 980px) {
  .l-footer-bnr__item {
    width: 242px;
  }
}

.l-footer__copy {
  display: block;
  line-height: 1;
  text-align: center;
  font-size: 10px;
  letter-spacing: .1em;
}

@media print, screen and (min-width: 980px) {
  .l-footer__copy {
    float: right;
    width: 700px;
    font-size: 12px;
  }
}

.l-footer .pagetop {
  position: absolute;
  right: 6vw;
  top: -105px;
  width: 29%;
  max-width: 110px;
}

.l-footer .pagetop a {
  display: block;
}

.l-footer .pagetop img {
  width: 100%;
  height: auto;
}

@media print, screen and (min-width: 980px) {
  .l-footer .pagetop {
    top: -107px;
    right: 7.6vw;
    width: auto;
  }
}

/* */
.l-header .l-gnav {
  display: none;
}

@media print, screen and (min-width: 980px) {
  .l-header .l-gnav {
    font-size: 13px;
    display: block;
    position: absolute;
    right: 5px;
    bottom: 0;
    padding-top: 48px;
    padding-bottom: 27px;
  }
  .l-header .l-gnav__item {
    display: inline-block;
    margin: 0 10px;
    vertical-align: middle;
  }
  .l-header .l-gnav__item:last-of-type {
    display: none;
  }
  .l-header .l-gnav__item a {
    position: relative;
    display: block;
    line-height: 20px;
    color: #fff;
    letter-spacing: 0.1em;
    transform: perspective(1px) translateZ(0);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .l-header .l-gnav__item a:first-of-type {
    margin-left: 0;
  }
  .l-header .l-gnav__item a:nth-last-of-type(1) {
    margin-right: 0;
  }
  .l-header .l-gnav__item a::before {
    position: absolute;
    right: 100%;
    bottom: -5px;
    left: 0;
    z-index: -1;
    height: 2px;
    content: "";
    background: #ce6a77;
    transition-timing-function: ease-out;
    transition-duration: 0.2s;
    transition-property: right;
  }
  .l-header .l-gnav__item a:hover {
    color: #fff;
  }
  .l-header .l-gnav__item a:hover::before {
    right: 0;
  }
}

.l-toggle .l-gnav {
  margin-top: 80px;
}

.l-toggle .l-gnav__list {
  padding: 0 10vw;
}

.l-toggle .l-gnav__item {
  margin-bottom: 43px;
}

.l-toggle .l-gnav__item a {
  display: block;
  padding: 10px 0;
  line-height: 1;
  color: #fff;
  font-size: 20px;
  letter-spacing: 0.1em;
}

@media print, screen and (min-width: 980px) {
  .l-toggle .l-gnav {
    margin-top: 95px;
  }
  .l-toggle .l-gnav__list {
    padding: 0 60px;
  }
  .l-toggle .l-gnav__item {
    vertical-align: middle;
    margin-bottom: 45px;
  }
  .l-toggle .l-gnav__item a {
    transform: perspective(1px) translateZ(0);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .l-toggle .l-gnav__item a:first-of-type {
    margin-left: 0;
  }
  .l-toggle .l-gnav__item a:nth-last-of-type(1) {
    margin-right: 0;
  }
  .l-toggle .l-gnav__item a:hover {
    color: #f9dade;
  }
}

/* */
.l-header {
  background: #ce6a77;
  height: 150px;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 90px;
}

@media print, screen and (min-width: 980px) {
  .l-header__inner {
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
  }
}

.l-header.enableTransition:not(.is-fixed) .l-site {
  transition: transform 0.3s cubic-bezier(0.55, 0.09, 0.68, 0.53);
}

.l-header.enableTransition.is-fixed .l-site {
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.l-header.is-fixed .l-site {
  position: fixed;
  transform: translateY(0);
  background: none;
}

@media print, screen and (min-width: 980px) {
  .l-header {
    height: auto;
  }
  .l-header:after {
    display: block;
    clear: both;
    content: "";
  }
}

.l-header__logo {
  text-align: center;
  padding: 22px 0 0;
}

@media print, screen and (min-width: 980px) {
  .l-header__logo {
    padding: 28px 0 30px;
  }
}

.l-header__logo .-img {
  display: inline-block;
  width: calc(364px / 2);
  height: calc(128px / 2);
  vertical-align: top;
}

@media print, screen and (min-width: 980px) {
  .l-header__logo .-img {
    width: 243px;
    height: 92px;
  }
}

.l-header__logo .-img img {
  width: 100%;
  height: auto;
}

.l-header .l-site {
  position: absolute;
  box-sizing: border-box;
  width: 50px;
  padding-top: 28px;
  height: 50px;
  top: 0;
  right: 4vw;
  z-index: 10;
}

@media print, screen and (min-width: 980px) {
  .l-header .l-site {
    position: fixed;
    width: 58px;
    height: 58px;
    right: 28px;
  }
}

.l-header .l-site__menu {
  background: #cb4557;
  position: absolute;
  top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: 20;
}

@media print, screen and (min-width: 980px) {
  .l-header .l-site__menu {
    width: 58px;
    height: 58px;
  }
}

.l-header .l-site__menu a {
  display: flex;
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  color: #fff;
}

@media print, screen and (min-width: 980px) {
  .l-header .l-site__menu a {
    width: 58px;
    height: 58px;
  }
}

.l-header .l-site__menu .line1,
.l-header .l-site__menu .line2,
.l-header .l-site__menu .line3 {
  position: absolute;
  display: block;
  width: 19px;
  height: 3px;
  pointer-events: none;
  content: '';
  background: #fff;
  border-radius: 1.5px;
}

@media print, screen and (min-width: 980px) {
  .l-header .l-site__menu .line1,
  .l-header .l-site__menu .line2,
  .l-header .l-site__menu .line3 {
    width: 24px;
  }
}

.l-header .l-site.-open .l-site__menu a .line1,
.l-header .l-site.-open .l-site__menu a .line2,
.l-header .l-site.-open .l-site__menu a .line3 {
  top: 18px;
  left: 12px;
}

.l-header .l-site.-open .l-site__menu a .line1 {
  transform: rotate(45deg);
}

.l-header .l-site.-open .l-site__menu a .line2 {
  display: none;
}

.l-header .l-site.-open .l-site__menu a .line3 {
  transform: rotate(-45deg);
}

.l-header .l-site.-close .l-site__menu a .line1 {
  top: 17px;
  right: 16px;
}

@media print, screen and (min-width: 980px) {
  .l-header .l-site.-close .l-site__menu a .line1 {
    top: 19px;
    right: 17px;
  }
}

.l-header .l-site.-close .l-site__menu a .line2 {
  top: 24px;
  right: 16px;
}

@media print, screen and (min-width: 980px) {
  .l-header .l-site.-close .l-site__menu a .line2 {
    top: 28px;
    right: 17px;
  }
}

.l-header .l-site.-close .l-site__menu a .line3 {
  top: 31px;
  right: 16px;
}

@media print, screen and (min-width: 980px) {
  .l-header .l-site.-close .l-site__menu a .line3 {
    top: 37px;
    right: 17px;
  }
}

.l-toggle {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: #ce6a77;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s linear;
}

@media print, screen and (min-width: 768px) {
  .l-toggle {
    width: 345px;
  }
}

.l-toggle.-show {
  visibility: visible;
  pointer-events: auto;
  opacity: 1;
  overflow-y: scroll;
}

.l-toggleWrap {
  content: '';
  display: block;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  transition: opacity 0.3s ease, visibility 0.3s linear;
  opacity: 0;
  visibility: hidden;
}

.l-toggleWrap.-hidden {
  pointer-events: none;
}

.l-toggleWrap.-show {
  opacity: 1;
  visibility: visible;
}

.l-toggle__inner {
  padding-bottom: 60px;
}

.l-toggle-site__btn {
  position: fixed;
  top: 19px;
  right: 14px;
  z-index: 10;
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media print, screen and (min-width: 980px) {
  .l-toggle-site__btn {
    top: 20px;
    right: 27px;
    width: 58px;
    height: 58px;
    cursor: pointer;
  }
}

.l-toggle-site__btn:before, .l-toggle-site__btn:after {
  content: '';
  pointer-events: none;
  width: 3px;
  height: 20px;
  background: #fff;
  display: block;
  position: absolute;
  border-radius: 1.5px;
}

@media print, screen and (min-width: 980px) {
  .l-toggle-site__btn:before, .l-toggle-site__btn:after {
    width: 4px;
    height: 30px;
    border-radius: 2px;
  }
}

.l-toggle-site__btn:before {
  transform: rotate(45deg);
}

.l-toggle-site__btn:after {
  transform: rotate(-45deg);
}

/* */
#News {
  margin-bottom: calc(52px - 16px);
  position: relative;
}

@media print, screen and (min-width: 980px) {
  #News {
    margin-bottom: 40px;
  }
}

.js-accordion {
  margin-top: -20px;
}

@media print, screen and (min-width: 980px) {
  .js-accordion {
    margin-top: -25px;
  }
}

.js-accordion__button {
  cursor: pointer;
}

.js-accordion__item {
  border-bottom: 1px solid #e9e9e9;
}

.js-accordion__item .js-accordion__button {
  padding-right: 25px;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
}

.js-accordion__item .js-accordion__button::after {
  content: "";
  display: block;
  top: 50%;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 5px 0 5px;
  border-color: #cb4557 transparent transparent transparent;
  transition: transform 0.3s ease;
  transform: translateY(-50%);
  position: absolute;
}

@media print, screen and (min-width: 980px) {
  .js-accordion__item .js-accordion__button {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.js-accordion__item.js-accordion__item.-open .js-accordion__button::after {
  transform: rotate(180deg);
}

.js-accordion__item .c-card__date {
  display: block;
  color: #878787;
  font-size: 12px;
  line-height: 0.95833;
  margin-bottom: 6px;
}

.js-accordion__item .c-card__date::before {
  content: '';
  display: block;
  height: 0;
  width: 0;
  margin-top: calc((1 - 0.95833) * 0.5em);
}

@media print, screen and (min-width: 980px) {
  .js-accordion__item .c-card__date {
    font-size: 14px;
    line-height: 1.64286;
  }
  .js-accordion__item .c-card__date::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((1 - 1.64286) * 0.5em);
  }
}

.js-accordion__item .c-card__title {
  color: #cb4557;
  font-size: 16px;
  line-height: 1.25;
}

.js-accordion__item .c-card__title::before {
  content: '';
  display: block;
  height: 0;
  width: 0;
  margin-top: calc((1 - 1.25) * 0.5em);
}

@media print, screen and (min-width: 980px) {
  .js-accordion__item .c-card__title {
    font-size: 18px;
    line-height: 1.44444;
  }
  .js-accordion__item .c-card__title::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((1 - 1.44444) * 0.5em);
  }
}

.js-accordion__content.v-enter-active, .js-accordion__content.v-leave-active {
  overflow-y: hidden;
  transition: height 0.4s ease-in-out;
}

.js-accordion__content::after {
  display: block;
  content: "";
  height: 20px;
}

@media print, screen and (min-width: 980px) {
  .js-accordion__content::after {
    height: 25px;
  }
}

.js-accordion__content img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.js-accordion__content iframe {
  max-width: 100%;
  width: 100%;
}

.js-accordion__content p {
  margin-top: 32px;
  line-height: 1.69231;
}

.js-accordion__content p::before {
  content: '';
  display: block;
  height: 0;
  width: 0;
  margin-top: calc((1 - 1.69231) * 0.5em);
}

@media print, screen and (min-width: 980px) {
  .js-accordion__content p {
    line-height: 1.57143;
  }
  .js-accordion__content p::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((1 - 1.57143) * 0.5em);
  }
}

.js-accordion__content a {
  color: #cb4557;
}

.blocker {
  background: #fff;
  z-index: 999999;
}

.modal {
  max-width: none;
  box-shadow: none;
  border-radius: none;
  text-align: center;
  width: 100%;
  padding: 15px 0;
}

@media print, screen and (min-width: 980px) {
  .modal {
    width: 90%;
  }
}

.modal img {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}

@media print, screen and (min-width: 980px) {
  .modal img {
    max-width: 700px;
    height: auto;
    max-height: none;
  }
}

.modal a {
  position: fixed;
  top: 30px;
  right: 20px;
  background: #cb4557;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

@media print, screen and (min-width: 980px) {
  .modal a {
    width: 58px;
    height: 58px;
  }
}

.modal a::before, .modal a::after {
  content: "";
  position: absolute;
  display: block;
  width: 19px;
  height: 3px;
  pointer-events: none;
  content: '';
  background: #fff;
  border-radius: 1.5px;
  top: 50%;
  left: 50%;
}

@media print, screen and (min-width: 980px) {
  .modal a::before, .modal a::after {
    width: 24px;
  }
}

.modal a::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.modal a::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.modal a.close-modal {
  display: none;
}
