@charset "utf-8";
//@include sp {}
//@include tb {}
//@include pc {}
//@include onlyTB{}
//@include onlyPC{}

/* */
.l-contents {
  padding: 0 4vw;
  z-index: 5;
  @include pc {
    position: relative;
    max-width: $point-wide;
    margin: 0 auto;
    @include clearfix;
    padding: 0 10px;
    position: static;
  }
  .l-main {
    margin-top: -33px;
    padding-bottom: 72px;
    @include pc {
      padding-top: 60px;
      padding-bottom: 80px;
      float: right;
      width: 700px;
      margin-top: 0;
    }
  }
}