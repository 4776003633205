@charset "utf-8";

$point-sp: 767px;
$point-tb: 768px;
$point-pc: 980px;
$point-wide: 1240px;

//== Colors
$c-primary: #ce6a77;
$c-primary02: #3d9bcc;
$c-text: #1c1c1c;
$c-base: #dc8590;


//@include sp{}
@mixin sp {
  @media screen and (max-width: $point-sp) {
    @content;
  }
}

//@include tb{}
@mixin tb {
  @media print,
  screen and (min-width: $point-tb) {
    @content;
  }
}

//@include pc{}
@mixin pc {
  @media print,
  screen and (min-width: $point-pc) {
    @content;
  }
}

//@include wide{}
@mixin wide {
  @media print,
  screen and (min-width: $point-wide) {
    @content;
  }
}

//@include onlyTB{}
@mixin onlyTB {
  @media (min-width: $point-tb) and (max-width: $point-pc) {
    @content;
  }
}

//@include onlyPC{}
@mixin onlyPC {
  @media (min-width: $point-pc) and (max-width: $point-wide) {
    @content;
  }
}

//@include linkColor($color);
@mixin linkColor($color) {
  color: $color;
  &:hover,
  &:active,
  &:focus {
    color: lighten($color, 20%);
  }
}

//@include clearfix;
@mixin clearfix {
  &:after {
    display: block;
    clear: both;
    content: "";
  }
}

//@include lhCrop;
//@include lhCrop(80/40);
@mixin lhCrop($line-height) {
  &::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((1 - #{$line-height}) * 0.5em);
  }
}

// @include ov;
@mixin ov {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 0.7;
    img {
      background-color: rgba(#fff, 0.01);
    }
  }
}
