@charset "utf-8";
//@include sp {}
//@include tb {}
//@include pc {}
//@include onlyTB{}
//@include onlyPC{}

/* */
.c-img__kyoe {
  display: none;
  position: fixed;
  width: 42%;
  bottom: -86px;
  pointer-events: all;
  left: 4vw;
  max-width: 160px;
  &::after {
    content: "";
    display: block;
    background: url("../img/common/img_character_title.png") no-repeat center;
    background-size: 100% auto;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  img {
    width: 100%;
    height: auto;
  }
  @include pc {
    display: block;
    width: 440px;
    left: 10vw;
    max-width: 440px;
    top: 20px;
    bottom: inherit;
  }
  &SP {
    position: fixed;
    width: 42%;
    pointer-events: all;
    left: 4vw;
    bottom: 0;
    transition: transform 0.3s cubic-bezier(.55, .09, .68, .53);
    transform: translateY(0);
    max-width: 160px;
    @include pc {
      display: none;
    }
    &::after {
      content: "";
      display: block;
      background: url("../img/common/img_character_title.png") no-repeat center;
      background-size: 100% auto;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    img {
      width: 100%;
      height: auto;
    }
    &.-hidden {
      transform: translateY(86px);
    }
  }
}