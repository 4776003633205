@charset "utf-8";
//@include sp {}
//@include tb {}
//@include pc {}
//@include onlyTB{}
//@include onlyPC{}

/* */
body {
  background: $c-base;
  color: $c-text;
  font-size: 13px;
  line-height: 1.6;
  @include font-JP;
  @include pc {
    font-size: 14px;
  }
}

html>body {
  min-width: 300px;
  @include pc {
    min-width: $point-wide;
  }
}

a {
  color: #cb4557;
  outline: none;
  -webkit-touch-callout: none;
}

a:hover {
  text-decoration: none;
}

a:focus,
*:focus {
  outline: none;
}

img {
  border: none;
  border-style: none;
  vertical-align: top;
}

ol,
ul,
li {
  list-style: none;
}


