@charset "utf-8";
//@include sp {}
//@include tb {}
//@include pc {}
//@include onlyTB{}
//@include onlyPC{}

/* */
//@include font-googleJP;
@mixin font-googleJP {
  font-family: 'Kosugi Maru', sans-serif;
}

//@include font-JP;
@mixin font-JP {
  @include font-googleJP;
}