@charset "utf-8";
//@include sp {}
//@include tb {}
//@include pc {}
//@include onlyTB{}
//@include onlyPC{}

/* */
.l-toggle,
.taglive_contents_tile .modal_wrap {
  z-index: 999999;
}

.l-toggleWrap,
.taglive_modal_overlay {
  z-index: 99999;
}

.c-kyoe,
.c-img__kyoe,
.c-img__kyoeSP {
  z-index: 9999;
}

.c-share {
  z-index: 10;
}

.l-footer {
  z-index: 5;
}