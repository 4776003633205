@charset "utf-8";
//@include sp {}
//@include tb {}
//@include pc {}
//@include onlyTB{}
//@include onlyPC{}

/* */
br.SPbr {
  display: inline;
  @include pc {
    display: none;
  }
}

br.PCbr {
  display: none;
  @include pc {
    display: inline;
  }
}

.SPview {
  display: block;
  @include pc {
    display: none;
  }
}

.PCview {
  display: none;
  @include pc {
    display: block;
  }
}

.u-textCenter {
  text-align: left;
}

.u-textLeft {
  text-align: left;
}

.u-textRight {
  text-align: right;
}

.u-floatCenter {
  float: left;
}

.u-floatLeft {
  float: left;
}

.u-floatRight {
  float: right;
}
