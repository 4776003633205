@charset "utf-8";
//@include sp {}
//@include tb {}
//@include pc {}
//@include onlyTB{}
//@include onlyPC{}

/* */
.c-kyoe {
  position: fixed;
  pointer-events: all;
  left: 1vw;
  bottom: 0;
  width: calc(400vw / 7.68);
  height: calc(668vw / 7.68);
  -webkit-tap-highlight-color: transparent;
  @include pc {
    left: 10vw;
    top: 20px;
    width: 440px;
    height: 100%;
    bottom: inherit;
  }
  #kyoe, #kyoe > * {
    position: absolute;
  }
  #kyoe > * {
    background: transparent 50% / cover no-repeat;
  }
  #kyoe {
    top: 50%;
    left: 50%;
    width: calc(352.5vw / 7.68); /* ← MEMO: 画面幅 1440px 時点で画像の横幅が 705px になる計算 */
    height: calc(668vw / 7.68);
    transform: translate(-50%, -50%);
  }
  #kyoe .body {
    width: calc(400vw / 7.68);
    height: calc(668vw / 7.68);
  }
  #kyoe .eye {
    top: calc(136vw / 7.68);
    left: calc(32vw / 7.68);
    width: calc(290vw / 7.68);
    height: calc(80vw / 7.68);
  }
  #kyoe .l-wing {
    top: calc(354vw / 7.68);
    right: calc(10vw / 7.68);
    width: calc(79.5vw / 7.68);
    height: calc(190vw / 7.68);
  }
  #kyoe .r-wing {
    top: calc(354vw / 7.68);
    left: calc(10vw / 7.68);
    width: calc(78.5vw / 7.68);
    height: calc(185vw / 7.68);
  }
  @media screen and (min-width: 768px) {
    #kyoe {
      width: calc(352.5px * 1.1);
      height: calc(668px * 1.1);
    }
    #kyoe .body {
      width: calc(400px * 1.1);
      height: calc(668px * 1.1);
    }
    #kyoe .eye {
      top: calc(136px * 1.1);
      left: calc(32px * 1.1);
      width: calc(290px * 1.1);
      height: calc(80px * 1.1);
    }
    #kyoe .l-wing {
      top: calc(354px * 1.1);
      right: calc(10px * 1.1);
      width: calc(79.5px * 1.1);
      height: calc(190px * 1.1);
    }
    #kyoe .r-wing {
      top: calc(354px * 1.1);
      left: calc(10px * 1.1);
      width: calc(78.5px * 1.1);
      height: calc(185px * 1.1);
    }
  }
  #kyoe .body {
    background-image: url(../img/common/kyoe_body.png);
  }
  #kyoe .eye {
    background-image: url(../img/common/kyoe_eye.png);
    background-position: 50% 0;
  }
  #kyoe .l-wing {
    background-image: url(../img/common/kyoe_l_wing.png);
    transform-origin: 20% 20%;
  }
  #kyoe .r-wing {
    background-image: url(../img/common/kyoe_r_wing.png);
    transform-origin: 80% 20%;
  }
  /* animation */
  #kyoe {
    transition: transform .16s cubic-bezier(0.55, 0.055, 0.675, 0.19); /* easeInCubic */
  }
  #kyoe.blink1 .eye {
    animation: blink-1 .3s steps(1);
  }
  #kyoe.blink2 .eye {
    animation: blink-2 .3s steps(1);
  }
  #kyoe.flip {
    transform: translate(-50%, -52%);
    transition-duration: 1.6s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); /* easeInOutCubic */
  }
  #kyoe.flip .l-wing {
    animation: l-flip 1.6s cubic-bezier(0.645, 0.045, 0.355, 1); /* easeOutCubic */
  }
  #kyoe.flip .r-wing {
    animation: r-flip 1.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  @keyframes blink-1 {
    0%, 100% { background-position: 50% 0; }
    16% { background-position: 50% 25%; }
    33% { background-position: 50% 100%; }
    50% { background-position: 50% 75%; }
    66% { background-position: 50% 25%; }
    84% { background-position: 50% 0; }
  }
  @keyframes blink-2 {
    0%, 100% { background-position: 50% 0; }
    14% { background-position: 50% 25%; }
    28% { background-position: 50% 100%; }
    42% { background-position: 50% 75%; }
    58% { background-position: 50% 50%; }
    72% { background-position: 50% 25%; }
    86% { background-position: 50% 0; }
  }
  @keyframes l-flip {
    0%, 100% { transform: none }
    8% { transform: rotate(-120deg); }
    16% { transform: rotate(-20deg); }
    25% { transform: rotate(-130deg); }
    31% { transform: rotate(-30deg); }
    36% { transform: rotate(-140deg); }
    40% { transform: rotate(-30deg); }
    44% { transform: rotate(-140deg); }
    48% { transform: rotate(-30deg); }
    52% { transform: rotate(-140deg); }
    56% { transform: rotate(-30deg); }
    60% { transform: rotate(-140deg); }
    64% { transform: rotate(-30deg); }
    68% { transform: rotate(-140deg); }
    72% { transform: rotate(-30deg); }
    76% { transform: rotate(-140deg); }
    81% { transform: rotate(-20deg); }
    87% { transform: rotate(-130deg); }
    94% { transform: rotate(-90deg); animation-timing-function: cubic-bezier(.3,.9,.6,1.1); }
  }
  @keyframes r-flip {
    0%, 100% { transform: none }
    8% { transform: rotate(120deg); }
    16% { transform: rotate(20deg); }
    25% { transform: rotate(130deg); }
    31% { transform: rotate(30deg); }
    36% { transform: rotate(140deg); }
    40% { transform: rotate(30deg); }
    44% { transform: rotate(140deg); }
    48% { transform: rotate(30deg); }
    52% { transform: rotate(140deg); }
    56% { transform: rotate(30deg); }
    60% { transform: rotate(140deg); }
    64% { transform: rotate(30deg); }
    68% { transform: rotate(140deg); }
    72% { transform: rotate(30deg); }
    76% { transform: rotate(140deg); }
    81% { transform: rotate(20deg); }
    87% { transform: rotate(130deg); }
    94% { transform: rotate(90deg); animation-timing-function: cubic-bezier(.3,.9,.6,1.1); }
  }
}