@charset "utf-8";
//@include sp {}
//@include tb {}
//@include pc {}
//@include onlyTB{}
//@include onlyPC{}

/* */
.l-header {
  background: $c-primary;
  height: 150px;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 90px;
  &__inner {
    @include pc {
      max-width: 1440px;
      margin: 0 auto;
      position: relative;
    }
  }
  &.enableTransition {
    &:not(.is-fixed) {
      .l-site {
        transition: transform 0.3s cubic-bezier(.55, .09, .68, .53);
      }
    }
    &.is-fixed {
      .l-site {
        transition: transform 0.3s cubic-bezier(.25, .46, .45, .94);
      }
    }
  }
  &.is-fixed {
    .l-site {
      position: fixed;
      transform: translateY(0);
      background: none;
    }
  }
  @include pc {
    height: auto;
    @include clearfix;
  }
  &__logo {
    text-align: center;
    padding: 22px 0 0;
    @include pc {
      padding: 28px 0 30px;
    }
    .-img {
      display: inline-block;
      width: calc(364px / 2);
      height: calc(128px / 2);
      vertical-align: top;
      @include pc {
        width: 243px;
        height: 92px;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  
  .l-site {
    position: absolute;
    box-sizing: border-box;
    width: 50px;
    padding-top: 28px;
    height: 50px;
    top: 0;
    right: 4vw;
    z-index: 10;
    @include pc {
      position: fixed;
      width: 58px;
      height: 58px;
      right: 28px;
    }
    &__menu {
      background: #cb4557;
      position: absolute;
      top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      z-index: 20;
      @include pc {
        width: 58px;
        height: 58px;
      }
      a {
        display: flex;
        display: block;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        color: #fff;
        @include pc {
          width: 58px;
          height: 58px;
        }
      }
      .line1,
      .line2,
      .line3 {
        position: absolute;
        display: block;
        width: 19px;
        height: 3px;
        pointer-events: none;
        content: '';
        background: #fff;
        border-radius: 1.5px;
        @include pc {
          width: 24px;
        }
      }
    }
    //ボタン
    &.-open {
      .l-site__menu {
        a {
          .line1,
          .line2,
          .line3 {
            top: 18px;
            left: 12px;
          }
          .line1 {
            transform: rotate(45deg);
          }
          .line2 {
            display: none;
          }
          .line3 {
            transform: rotate(-45deg);
          }
        }
      }
    }
    &.-close {
      .l-site__menu {
        a {
          .line1 {
            top: 17px;
            right: 16px;
            @include pc {
              top: 19px;
              right: 17px;
            }
          }
          .line2 {
            top: 24px;
            right: 16px;
            @include pc {
              top: 28px;
              right: 17px;
            }
          }
          .line3 {
            top: 31px;
            right: 16px;
            @include pc {
              top: 37px;
              right: 17px;
            }
          }
        }
      }
    }
  }
}

.l-toggle {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: $c-primary;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s linear;
  @include tb {
    width: 345px;
  }
  &.-show {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    overflow-y: scroll;
  }
  &Wrap {
    content: '';
    display: block;
    width: 100vw;
    height: 100%;
    background: rgba(#000, 0.4);
    position: fixed;
    transition: opacity 0.3s ease, visibility 0.3s linear;
    opacity: 0;
    visibility: hidden;
    &.-hidden {
      pointer-events: none;
    }
    &.-show {
      opacity: 1;
      visibility: visible;
    }
  }
  &__inner {
    padding-bottom: 60px;
  }
  &-site {
    &__btn {
      position: fixed;
      top: 19px;
      right: 14px;
      z-index: 10;
      width: 50px;
      height: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include pc {
        top: 20px;
        right: 27px;
        width: 58px;
        height: 58px;
        cursor: pointer;
      }
      &:before,
      &:after {
        content: '';
        pointer-events: none;
        width: 3px;
        height: 20px;
        background: #fff;
        display: block;
        position: absolute;
        border-radius: 1.5px;
        @include pc {
          width: 4px;
          height: 30px;
          border-radius: 2px;
        }
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}