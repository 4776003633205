@charset "utf-8";
//@include sp {}
//@include tb {}
//@include pc {}
//@include onlyTB{}
//@include onlyPC{}

/* */
.c-card {
  background: #fff;
  border-radius: 10px;
  padding: 40px 20px;
  margin-bottom: 20px;
  @include pc {
    border-radius: 20px;
    padding: 40px;
    margin-bottom: 40px;
  }
  
  .c-release {
    &__title {
      font-size: 10px;
      line-height: 1;
      color : #878787;
      @include pc {
        font-size: 12px;
      }
    }
    &__item {
      border-bottom: 1px solid #e9e9e9;
      padding: 20px 0;
      &:last-of-type {
        border-bottom: none;
        padding-bottom: 0;
      }
      @include pc {
        padding: 30px 0 25px;
        &:first-of-type {
          padding-top: 20px;
        }
      }
      h3 {
        font-size: 15px;
        @include lhCrop(56/30);
        line-height: (56/30);
        margin-bottom: 5px;
        color: $c-primary;
        letter-spacing: 0.03em;
        @include pc {
          margin-bottom: 15px;
          font-size: 18px;
          @include lhCrop(32/18);
          line-height: (32/18);
        }
      }
      h4 {
        @include lhCrop(44/26);
        line-height: (44/26);
        margin-bottom: 10px;
        color: $c-primary;
        letter-spacing: 0.03em;
        @include pc {
          @include lhCrop(22/14);
          line-height: (22/14);
        }
      }
      .img {
        img {
          width: 100%;
          height: auto;
          margin-bottom: 15px;
        }
      }
    }
    .-release3 {
      @include pc {
        h3 {
          margin-bottom: 5px;
        }
        h4 {
          margin-bottom: 0;
        }
      }
      p {
        margin-bottom: 20px;
      }
      ol {
        li {
          text-indent: -1.5em;
          margin-left: 1.5em;
          @for $i from 1 through 30 {
            &:nth-child(#{$i}):before {
              content: '#{$i}：';
            }
          }
        }
      }
    }
    .-release2 {
      display: flex;
      flex-wrap: wrap;
      flex: 0 0 auto;
      .release2__wrap {
        display: contents;
        @include pc {
          display: block;
          float: left;
          width: 160px;
        }
      }
      .release2__1 {
        order: 1;
        width: 100%;
      }
      .release2__2 {
        order: 3;
        width: 100%;
      }
      .release2__3 {
        order: 2;
        width: 100%;
      }
      .release2__4 {
        order: 4;
        width: 100%;
        .c-btn {
          margin-bottom: 35px;
        }
      }
      .release2__5 {
        order: 5;
        width: 100%;
      }
      .release2__1,
      .release2__2 {
        margin-bottom: 20px;
        figure {
          width: 37.3%;
          margin: 0 auto;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
      .release2__1Caption,
      .release2__2Caption {
        text-align: center;
        color: #878787;
        font-size: 10px;
        margin-top: 10px;
        @include pc {
          font-size: 12px;
        }
      }
      .release2__5 {
        .c-btn {
          margin-bottom: 40px;
        }
        h4 {
          margin-bottom: 5px;
        }
        .notice {
          font-size: 11px;
          margin-bottom: 5px;
        }
      }
      @include pc {
        display: block;
        @include clearfix;
        .release2__1,
        .release2__2 {
          figure {
            width: 100%;
          }
          img {
            width: 100%;
            height: auto;
          }
        }
        .release2__1 {
          margin-bottom: 30px;
        }
        .release2__3,
        .release2__4 {
          padding-left: 200px;
        }
        .release2__5 {
          clear: both;
          @include clearfix;
          .c-btn {
            float: left;
            width: 160px;
            margin-bottom: 0;
            a {
              width: 100%;
              padding-left: 0;
              padding-right: 0;
            }
          }
          h4 {
            margin-bottom: 5px;
            padding-left: 200px;
          }
          .img {
            padding-left: 200px;
          }
          .notice {
            padding-left: 200px;
            font-size: 12px;
            margin-bottom: 5px;
          }
        }
      }
      h4 {
        font-size: 15px;
        @include lhCrop(56/30);
        line-height: (56/30);
        margin-bottom: 15px;
        letter-spacing: 0.03em;
        color: $c-text;
        @include pc {
          font-size: 18px;
          @include lhCrop(32/18);
          line-height: (32/18);
        }
        b {
          color: $c-primary;
        }
      }
      h5 {
        font-size: 13px;
        @include lhCrop(44/26);
        line-height: (44/26);
        margin-bottom: 10px;
        letter-spacing: 0.03em;
        @include pc {
          font-size: 14px;
          @include lhCrop(22/14);
          line-height: (22/14);
          margin-bottom: 0;
        }
      }
      ul {
        margin-bottom: 35px;
        @include pc {
          margin-bottom: 20px;
        }
        li {
          text-indent: -1em;
          margin-left: 1em;
        }
      }
    }
    
    .-release1 {
      display: flex;
      flex-wrap: wrap;
      .c-release__img {
        order: 3;
        width: 100%;
        span {
          display: block;
          width: 37.3%;
          margin: 40px auto;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
      h3 {
        order: 1;
      }
      ul {
        order: 2;
      }
      .c-btn {
        order: 4;
        width: 100%;
        a {
          text-align: center;
        }
      }
      @include pc {
        display: block;
        @include clearfix;
        .c-release__img {
          float: left;
          width: 160px;
          margin: 0;
          span {
            width: 100%;
            margin: 0;
          }
          img {
            width: 100%;
            height: auto;
          }
        }
        h3,
        ul,
        .c-btn {
          padding-left: 200px;
        }
        .c-btn {
          margin-top: 20px;
        }
      }
    }
  }
  
  .c-prifile {
    &__title {
      font-size: 10px;
      line-height: 1;
      color : #878787;
      margin-bottom: 14px;
      @include pc {
        font-size: 12px;
        margin-bottom: 30px;
      }
    }
    &__img {
      span {
        display: block;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    &__caption {
      display: block;
      font-size: 15px;
      color: $c-primary;
      margin-top: 26px;
      @include pc {
        font-size: 18px;
        margin-top: 30px;
      }
    }
    &__text {
      margin-top: 20px;
      line-height: (44/26);
      @include pc {
        margin-top: 23px;
        line-height: (22/14);
      }
    }
  }
  
  .c-movie {
    &__title {
      font-size: 10px;
      line-height: 1;
      color : #878787;
      margin-bottom: 14px;
      @include pc {
        font-size: 12px;
        margin-bottom: 30px;
      }
    }
    &__body {
      iframe {
        width: 100%;
        height: 100%;
        @include pc {
          width: 620px;
          height: 348px;
        }
      }
    }
  }
  
  .c-lyric {
    &__title {
      font-size: 10px;
      line-height: 1;
      color : #878787;
      margin-bottom: 14px;
      @include pc {
        font-size: 12px;
        margin-bottom: 30px;
      }
    }
    &__img {
      span {
        img {
          width: 100%;
          height: auto;
          @include pc {
            width: 560px;
            margin: 0 auto;
          }
        }
      }
    }
    &__caption {
      display: block;
      font-size: 15px;
      color: $c-primary;
      margin-top: 26px;
      @include pc {
        font-size: 18px;
        margin-top: 30px;
      }
    }
    &__text {
      margin-top: 20px;
      line-height: (44/26);
      @include pc {
        margin-top: 23px;
        line-height: (22/14);
      }
    }
  }
  &__wrap {
    @include pc {
      @include clearfix;
      #Twitter {
        float: left;
        width: calc(50% - 10px);
        box-sizing: border-box;
      }
      #Instagram,
      #Profile {
        float: right;
        width: calc(50% - 10px);
        margin-bottom: 20px;
        box-sizing: border-box;
      }
    }
  }
}