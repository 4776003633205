@charset "utf-8";
//@include sp {}
//@include tb {}
//@include pc {}
//@include onlyTB{}
//@include onlyPC{}

/* */
#News {
  margin-bottom: calc(52px - 16px);
  position: relative;
  @include pc {
    margin-bottom: 40px;
  }
}
.js-accordion {
  margin-top: -20px;
  @include pc {
    margin-top: -25px;
  }
  &__button {
    cursor: pointer;
  }
  &__item {
    border-bottom: 1px solid #e9e9e9;
    .js-accordion__button {
      padding-right: 25px;
      padding-top: 20px;
      padding-bottom: 20px;
      position: relative;
      &::after {
        content: "";
        display: block;
        top: 50%;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 5px 0 5px;
        border-color: #cb4557 transparent transparent transparent;
        transition: transform 0.3s ease;
        transform: translateY(-50%);
        position: absolute;
      }
      @include pc {
        padding-top: 25px;
        padding-bottom: 25px;
      }
    }
    &.js-accordion__item.-close {
    }
    &.js-accordion__item.-open {
      .js-accordion__button::after {
        transform: rotate(180deg);
      }
    }
    .c-card {
      &__date {
        display: block;
        color: #878787;
        @include lhCrop(23/24);
        font-size: 12px;
        line-height: (23/24);
        margin-bottom: 6px;
        @include pc {
          @include lhCrop(23/14);
          font-size: 14px;
          line-height: (23/14);
        }
      }
      &__title {
        color: #cb4557;
        @include lhCrop(40/32);
        font-size: 16px;
        line-height: (40/32);
        @include pc {
          @include lhCrop(26/18);
          font-size: 18px;
          line-height: (26/18);
        }
      }
    }
  }
  &__content {
    &.v-enter-active,
    &.v-leave-active {
      overflow-y: hidden;
      transition: height 0.4s ease-in-out;
    }
    &::after {
      display: block;
      content: "";
      height: 20px;
      @include pc {
        height: 25px;
      }
    }
    img {
      max-width: 100%;
      width: 100%;
      height: auto;
    }
    iframe {
      max-width: 100%;
      width: 100%;
    }
    p {
      margin-top: 32px;
      @include lhCrop(44/26);
      line-height: (44/26);
      @include pc {
        @include lhCrop(22/14);
        line-height: (22/14);
      }
    }
    a {
      color: #cb4557;
    }
  }
}
.blocker {
  background: #fff;
  z-index: 999999;
  
}
.modal {
  max-width: none;
  box-shadow: none;
  border-radius: none;
  text-align: center;
  width: 100%;
  padding: 15px 0;
  @include pc {
    width: 90%;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    @include pc {
      max-width: 700px;
      height: auto;
      max-height: none;
    }
  }
}
.modal a {
  position: fixed;
  top: 30px;
  right: 20px;
  background: #cb4557;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  @include pc {
    width: 58px;
    height: 58px;
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 19px;
    height: 3px;
    pointer-events: none;
    content: '';
    background: #fff;
    border-radius: 1.5px;
    top: 50%;
    left: 50%;
    @include pc {
      width: 24px;
    }
  }
  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
.modal a.close-modal {
  display: none;
}