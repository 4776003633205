@charset "utf-8";
//@include sp {}
//@include tb {}
//@include pc {}
//@include onlyTB{}
//@include onlyPC{}

/* */
.c-taglive {
  @include pc {
    @include clearfix;
    margin-bottom: 20px;
  }
  &__item {
    border: 3px solid #000;
    border-radius: 3px;
    background: #fff;
    margin-bottom: 20px;
    @include pc {
      border: 6px solid #000;
      width: calc(50% - 10px);
      box-sizing: border-box;
      margin-bottom: 0;
      &:nth-of-type(odd) {
        float: left;
      }
      &:nth-of-type(even) {
        float: right;
      }
    }
  }
  &__title {
    border-bottom: 5px solid #000;
    @include pc {
      border-bottom: 6px solid #000;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  &__inner {
    @include pc {
      height: 680px;
      overflow-y: auto;
    }
  }
  .taglive_contents_tile {
    width: 100%!important;
    box-sizing: border-box;
    margin: 0 auto 34px !important;
    @include onlyTB {
      width: 96%!important;
    }
    @include pc {
      width: 288px!important;
      margin: 0 0 0 21px!important;
    }
  }
  .taglive_contents_tile .post {
    border-radius: 0;
    margin-left: 16px;
    margin-bottom: 16px;
    @include pc {
      width: 125px;
      margin-left: 10px;
      margin-bottom: 12px;
    }
  }
  .taglive_contents_tile .post {
    width: 41%!important;
    @include onlyTB {
      width: 44%!important;
    }
    @include pc {
      width: 44%!important;
    }
  }
  .taglive_contents_tile .inst_photo, .taglive_contents_tile .twitter_photo {
    width: 36vw!important;
    height: 36vw;
    background: #fff;
    @include onlyTB {
      width: 39vw!important;
      height: 39vw;
    }
    @include pc {
      width: 126px!important;
      height: 126px!important;
    }
  }
  .taglive_img_info {
    width: 100%;
    box-sizing: border-box;
    padding-right: 20px;
    padding-top: 14px;
    padding-bottom: 8px;
  }
  .taglive_img_info a, .taglive_img_info img {
    width: 17px;
    height: auto;
  }
  .taglive_next_button_tile {
    margin: 0 auto 34px;
  }
  .c-btn.-more {
    margin: 0 10px 20px;
    @include pc {
      margin: 34px auto 0;
    }
  }
}
.taglive_modal_overlay {
  background: rgba($c-base,.9);
}
.c-taglive .taglive_contents_tile .post {
  border: none;
}
.taglive_contents_tile .taglive_entry_text {
  padding: 0;
}
.taglive_contents_tile .modal_meta a {
  color: #878787;
  text-decoration: none;
}
.taglive_contents_tile .inst_profile {
  background: url(../img/common/icon_instagram.svg) no-repeat 2px .5em;
  background-size: 16px auto;
}
.taglive_contents_tile .twitter_profile {
  background: url(../img/common/icon_twitter_gry.svg) no-repeat 2px .5em;
  background-size: 16px auto;
}
.taglive_contents_tile .modal_meta_inst {
  background: url(../img/common/icon_instagram.svg) no-repeat right 10px;
  background-size: 16px auto;
}
.taglive_contents_tile .modal_meta_twitter {
  background: url(../img/common/icon_twitter_gry.svg) no-repeat right 10px;
  background-size: 16px auto;
}
.taglive_contents_tile .modal_content {
  width: 70%;
  @include sp {
    top: 78px!important;
  }
  @include pc {
    width: 800px;
  }
}
.taglive_contents_tile .modal_profile a.modal_user,
.taglive_contents_tile .twitter_profile .profile_txt a, .taglive_contents_tile .inst_profile .profile_txt a {
  color: $c-primary;
}
.taglive_contents_tile .modal_change {
  @include sp {
    top: 102%;
  }
}
.taglive_contents_tile .modal_change.prev {
  background-image: url(../img/common/arrow.svg);
  transform: rotate(180deg);
  left: 0;
  @include tb {
    left: -55px;
  }
}
.taglive_contents_tile .modal_change.next {
  background-image: url(../img/common/arrow.svg);
  right: 0;
  @include tb {
    right: -55px;
  }
}
.taglive_contents_tile .modal_close {
  position: fixed;
  top: 20px;
  right: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: #cb4557;
  border-radius: 50%;
  @include pc {
    width: 58px;
    height: 58px;
    right: 28px;
  }
  &::before,
  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 19px;
    height: 3px;
    pointer-events: none;
    content: "";
    content: '';
    background: #fff;
    border-radius: 1.5px;
    @include pc {
      width: 24px;
    }
  }
  &::before {
    transform: translate(-50%,-50%) rotate(45deg);
  }
  &::after {
    transform: translate(-50%,-50%) rotate(-45deg);
  }
}