@charset "utf-8";
//@include sp {}
//@include tb {}
//@include pc {}
//@include onlyTB{}
//@include onlyPC{}

/* */
.c-btn {
  a,
  &.-more {
    display: block;
    color: #fff;
    text-decoration: none;
    background: #cb4557;
    border: 3px solid #cb4557;
    color: #fff;
    text-align: center;
    font-size: 13px;
    border-radius: 28px;
    line-height: 1;
    @include pc {
      display: inline-block;
      margin: 0 auto;
      padding: 18px 25px;
    }
  }
  a {
    padding: 18px 30px;
    position: relative;
    @include pc {
      transition: opacity 0.3s cubic-bezier(.25, .46, .45, .94);
      opacity: 1;
      &:hover {
        opacity: .7;
      }
      .c-release__item.-release2 & {
        display: block;
        padding: 18px 0;
      }
    }
  }
  &.-more {
    padding: 18px 5px;
    background: #fff;
    cursor: pointer;
    color: #cb4557;
    margin-top: 22px;
    @include pc {
      margin-top: 25px;
      display: block;
      width: 240px;
      transition: 0.3s cubic-bezier(.25, .46, .45, .94);
      &:hover {
        background: #cb4557;
        color: #fff;
        opacity: .7;
      }
    }
  }
//  &:not(.more) a {
//    &::after {
//      content: "";
//      width: 12px;
//      height: 12px;
//      background: url("../img/common/arrow.svg") no-repeat center;
//      background-size: 12px auto;
//      position: absolute;
//      right: 16px;
//      top: 50%;
//      transform: translateY(-50%);
//      @include pc {
//        background-size: 18px auto;
//        width: 18px;
//        height: 18px;
//        right: 23px;
//        transition: right 0.2s ease-in-out;
//      }
//    }
//    @include pc {
//      &:hover {
//        &::after {
//          right: 20px;
//        }
//      }
//    }
//  }
}